import Button from "../components/ui/button";
import { useNavigate } from "react-router";
import Banner from "../components/banner/banner";
import { Routes } from "../config/routes";
import Sliders from "../components/sliders/sliders";
import { ArrowNext } from "../components/icons";
import DefaultLayout from "../components/layouts/default";
import { Slide } from "../types";
import { useModalAction } from "../components/ui/modal/modal.context";

export const Home = () => {
  const { openModal } = useModalAction();
  const navigate = useNavigate();

  const slides: Slide[] = [
    {
      link: "/events",
      title: "Valentijn",
      description:
        "Vrijdag, 14 Februari: Speciale valentijns menu met live muziek bij gebracht door Kim & Phil!",
      image:
        "https://drive.google.com/thumbnail?id=1IvM2GddQSHx0qmErUSCcxRU9va66YcZc&sz=w1000",
    },
  ];
  return (
    <DefaultLayout>
      {/* <Seo title={type?.name} url={type?.slug} images={type?.banners} /> */}

      <Banner
        banners={[
          {
            id: "0",
            title: "Bistro Papillon",
            description: "Welkom bij",
            image: "/burger.jpg",
            logo: true,
          },
        ]}
      />

      <div className="w-full flex justify-center align-middle bg-white px-8 sm:px-0">
        <div className="pt-32 flex justify-center flex-col items-center">
          <h1
            className={
              "text-2xl font-bold tracking-tight text-black lg:text-2xl xl:text-5xl"
            }
          >
            {"Welkom!"}
          </h1>
          <p className={"text-md font-bold tracking-tight text-accent pt-4"}>
            {"Welkom bij Bert en Kelly"}
          </p>

          <p
            className={
              "text-md font-normal tracking-tight text-black max-w-4xl py-5 text-center"
            }
          >
            {
              "Graag nodigen wij jullie het gehele jaar door uit bij ons in de bistro. Hier is iedereen welkom, van kampeerder tot woonachtige in en rond de streek. Wij bieden een zeer uitgebreide kaart aan voor jong en oud. Met onze buitenspeeltuin, zwemvijver en binnen speelhoek kunnen de kleinsten onder ons zich na het eten steeds vermaken terwijl u nog van een heerlijk dessert of drankje geniet. Bekijk zeker ook onze allergenenlijst voor meer informatie over onze gerechten. Reserveer nu via bistro@papillonkinrooi.be of neem telefonisch contact op +32 456 269401. Tot snel!"
            }
          </p>
          <p
            className={
              "text-md font-normal tracking-tight text-black max-w-4xl py-5 text-center"
            }
          ></p>
        </div>
      </div>

      <div className="flex items-center justify-center space-x-4 rtl:space-x-reverse align-Middle visible md:invisible">
        <Button
          className="inline-flex h-12 shrink-0 items-center align-middle justify-center rounded border border-transparent bg-accent px-6 py-6 text-xl font-bold leading-none text-center transition duration-300 ease-in-out hover:bg-accent-hover focus:shadow focus:outline-none focus:ring-1 focus:ring-accent-700"
          onClick={() => openModal()}
        >
          {"Reserveer nu"}
        </Button>
      </div>
      <div className=" w-full z-100 text-lg font-bold">
        Bistro zal gesloten zijn van zondag 2 maart 2025 tot woensdag 12 maart
        2025 wegens jaarlijkse vakantie
      </div>
      <div className="w-full flex justify-center align-middle pb-20 bg-white px-8 sm:px-0">
        <div className="pt-16 flex justify-center flex-col items-center">
          <h1
            className={
              "text-2xl font-bold tracking-tight text-black lg:text-2xl xl:text-4xl"
            }
          >
            {"Acties"}
          </h1>
          <p className={"text-md font-bold tracking-tight text-accent pt-4"}>
            {"Elke week"}
          </p>

          <p
            className={
              "text-md font-bold tracking-tight text-black max-w-4xl pt-5 text-center"
            }
          >
            Papillon à volonté maandagen
          </p>
          <p
            className={
              "text-md font-normal tracking-tight max-w-4xl  text-center"
            }
          >
            Elke maandag ribbetjes en/ of scampi’s à volonté voor 22,50 euro per
            persoon.
          </p>
          <p
            className={
              "text-md font-bold tracking-tight text-black max-w-4xl pt-5 text-center"
            }
          >
            Steak donderdagen
          </p>
          <p
            className={
              "text-md font-normal tracking-tight text-black max-w-4xl text-center"
            }
          >
            Elke donderdag steak (incl. salade, frietjes en mayonaise) aan 17,50
            euro per persoon.
          </p>
          <p
            className={
              "text-md font-bold tracking-tight text-black max-w-4xl pt-5 text-center"
            }
          >
            Kinder vrijdagavonden
          </p>
          <p
            className={
              "text-md font-normal tracking-tight text-black max-w-4xl text-center"
            }
          >
            Bij elke bestelling van twee hoofdgerechten krijgt u een
            kindergerecht gratis.
          </p>
        </div>
      </div>

      <div className="w-full flex justify-center flex-col items-center bg-white relative">
        <h1
          className={
            "text-2xl font-bold tracking-tight text-black lg:text-2xl xl:text-4xl"
          }
        >
          {"Events"}
        </h1>
        <Button
          className="mt-2 sm:mt-0 sm:absolute right-6 top-0 z-10"
          onClick={() => navigate(Routes.events)}
        >
          {"Alle evenementen"}
          <ArrowNext></ArrowNext>
        </Button>
        <Sliders slides={slides} />
      </div>
      <div
        className="w-full flex-col md:flex-row  flex justify-center items-center 
             bg-center bg-local bg-cover px-6 md:px-0	 bg-white "
      >
        <div className="flex flex-col md:flex-row">
          <img
            className=" md:w-auto object-cover h-[80vh] "
            src={
              "https://drive.google.com/thumbnail?id=1L4_LhLlAsJP7s_fLFHqJeNN6TiO1ylKO&sz=w1000"
            }
          />
        </div>
      </div>
      <div
        className="w-full flex-col md:flex-row  flex justify-center items-center 
             bg-center bg-local bg-cover px-6 md:px-0	 bg-white "
      >
        <div className="flex flex-col md:flex-row">
          <img
            className=" md:w-auto object-cover h-[80vh] "
            src={
              "https://drive.google.com/thumbnail?id=1X7gNa7a9XhxqdJyvegxHdUf-0kUxDKsa&sz=w1000"
            }
          />
        </div>
      </div>
      {/* <div className="w-full flex-col justify-center align-middle 
             bg-center bg-local bg-cover 	 bg-black ">


                <div className='flex flex-row items-center  w-full  flex-1 bg-black bg-opacity-90 px-4 sm:px-0'   >


                    <div className='h-fit w-full sm:w-3/6 justify-center items-start flex relative flex-1 py-20' >

                        <div className='pt-12 flex justify-center flex-col items-center h-full w-fill '  >
                            <h1
                                className={'text-2xl font-bold tracking-tight text-white lg:text-4xl xl:text-5xl'}
                            >
                                {"Suggesties"}
                            </h1>
                            <p className={'text-lg font-bold tracking-tight text-accent max-w-4xl pt-10 text-center'}>
                                {"Voorgerecht"}
                            </p>
                            <p className={'text-md font-normal tracking-tight text-white max-w-4xl text-center'}>
                                Scampi Papillon of Look 15<br />
                            </p>
                            <p className={'text-lg font-bold tracking-tight text-accent max-w-4xl pt-5 text-center'}>
                                {"Hoofdgerecht"}
                            </p>
                            <p className={'text-md font-normal tracking-tight text-white max-w-4xl text-center'}>

                                Mosselen Natuur €28<br />
                                of<br />
                                Mosselen witte wijn €30<br />
                                of<br />
                                Mosselen look €30<br />
                                of<br />
                                Mosselen Papillon (Karmeliet en chorizo) €33<br />
                            </p>
                            <p className={'text-lg font-bold tracking-tight text-accent max-w-4xl pt-5 text-center'}>
                                {"Dessert"}
                            </p>
                            <p className={'text-md font-normal tracking-tight text-white max-w-4xl text-center'}>
                                coupe aardbeien €10<br />
                            </p>

                            <Button className='my-10' onClick={() => navigate(Routes.menu)}
                            >{"Volledige menu"}</Button>
                        </div>
                    </div>

                </div>
            </div > */}
    </DefaultLayout>
  );
};
